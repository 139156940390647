import React, { useEffect, useState } from 'react';
import { Loading, TitlePrimary, ButtonPrimary } from '@ironhack/ih-components';
import { QueryCluster } from '../../data';
import ClusterCard from '../../interfaces/ClusterCard';
import {
  sortProfiles,
  profileToCluster,
  filterClusterStudents
} from '../../lib/collections-functions';
import GlobalStyles from '../../components/global-styles';
import PageHeader from '../../components/page-header';
import StudentClusterList from '../../components/student-cluster-list';
import Layout from './styles';

interface Props {
  match: {
    params: {
      clusterId: string;
    };
  };
}

const Cluster = (props: Props): JSX.Element => {
  const { clusterId } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [cards, setCards] = useState<{
    web: Array<ClusterCard>;
    uxui: Array<ClusterCard>;
    data: Array<ClusterCard>;
  }>({
    web: [],
    uxui: [],
    data: []
  });

  useEffect(() => {
    QueryCluster(clusterId)
      .then(res => res.json())
      .then(res => {
        if (!res.result) {
          setIsExpired(true);
        } else {
          const { profiles } = res.result;
          const mappedProfiles = profiles
            .sort(sortProfiles)
            .map(profileToCluster);
          const web = filterClusterStudents(mappedProfiles, 'wd');
          const uxui = filterClusterStudents(mappedProfiles, 'ux');
          const data = filterClusterStudents(mappedProfiles, 'da');
          setCards({ web, uxui, data });
        }
        setIsLoading(false);
      });
  }, [clusterId]);

  if (isExpired) {
    return (
      <Layout className="expired">
        <GlobalStyles />
        <PageHeader text="" />
        <TitlePrimary>404 - Page not found</TitlePrimary>
        <p>The candidate page doesn&apos;t exist anymore.</p>
        <p>Please, contact Ironhack for more information.</p>
        <ButtonPrimary
          onClick={(): void =>
            window.location.assign('mailto:outcomes@ironhack.com')
          }
          lightTheme
        >
          Contact us
        </ButtonPrimary>
      </Layout>
    );
  }

  return (
    <Layout>
      <GlobalStyles />
      <PageHeader text="Candidates" />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <StudentClusterList course="web" cards={cards.web} />
          <StudentClusterList course="uxui" cards={cards.uxui} />
          <StudentClusterList course="data" cards={cards.data} />
        </>
      )}
    </Layout>
  );
};

export default Cluster;
