import React from 'react';
import Header from './styles';
import { ReactComponent as Logo } from '../../assets/logo.svg';

interface Props {
  text: string;
}

const PageHeader = (props: Props): JSX.Element => (
  <Header>
    <Logo />
    {props.text}
  </Header>
);

export default PageHeader;
