import React from 'react';
import { TrackTitle, CardGenericList } from '@ironhack/ih-components';
import ClusterCard from '../../interfaces/ClusterCard';

const coursesTitle: { [key: string]: string } = {
  web: 'Web Development',
  uxui: 'UX/UI Design',
  data: 'Data Analytics'
};

interface Props {
  course: string;
  cards: Array<ClusterCard>;
}

const StudentClusterList = (props: Props): JSX.Element | null => {
  const { course, cards } = props;
  if (cards.length === 0) {
    return null;
  }

  return (
    <>
      <TrackTitle course={course} title={coursesTitle[course] || ''} />
      <CardGenericList cards={cards} className="cards-section" />
    </>
  );
};

export default StudentClusterList;
