import Profile from '../interfaces/StudentInformation';
import { getStudentName } from './utils';
import ClusterCard from '../interfaces/ClusterCard';

const sortProfiles = (profile1: Profile, profile2: Profile): number => {
  const name1 = getStudentName(profile1.name, profile1.surname);
  const name2 = getStudentName(profile2.name, profile2.surname);

  if (name1 > name2) {
    return 1;
  }
  if (name1 < name2) {
    return -1;
  }
  return 0;
};

const profileToCluster = (profile: Profile): ClusterCard => {
  return {
    displayName: getStudentName(profile.name, profile.surname),
    description: profile.quote,
    linkText: 'View candidate',
    linkHref: `https://profile.ironhack.com/${profile.slugUrl}`,
    track: profile.track
  };
};

const filterClusterStudents = (
  students: Array<ClusterCard>,
  course: string
): Array<ClusterCard> => {
  return students.filter((profile: ClusterCard) => profile.track === course);
};

export { sortProfiles, profileToCluster, filterClusterStudents };
