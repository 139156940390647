import { DefaultTheme } from 'styled-components';

const black = '0, 0, 0';
const darkBlue = '45, 53, 76';
const darkCyan = '0, 139, 187';
const darkGreen = '0, 71, 102';
const errorRed = '236, 58, 53';
const lightBlue = '45, 197, 250';
const white = '255, 255, 255';

const alphaColor = (color: string, alpha: string): string =>
  `rgba(${color}, ${alpha})`;

const theme: DefaultTheme = {
  colors: {
    black: `rgb(${black})`,
    blackTransparent: (alpha: string): string => alphaColor(black, alpha),
    darkBlue: `rgb(${darkBlue})`,
    darkBlueTransparent: (alpha: string): string => alphaColor(darkBlue, alpha),
    darkCyan: `rgb(${darkCyan})`,
    darkCyanTransparent: (alpha: string): string => alphaColor(darkCyan, alpha),
    darkGreen: `rgb(${darkGreen})`,
    darkGreenTransparent: (alpha: string): string =>
      alphaColor(darkGreen, alpha),
    errorRed: `rgb(${errorRed})`,
    errorRedTransparent: (alpha: string): string => alphaColor(errorRed, alpha),
    lightBlue: `rgb(${lightBlue})`,
    white: `rgb(${white})`
  }
};

export { theme };
