import styled from 'styled-components';
import { screenResolutions } from '../_variables';

export default styled.h1`
  align-items: center;
  color: rgb(45, 53, 76);
  display: flex;
  font-size: 2rem;
  justify-content: flex-start;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;

  svg {
    margin-right: 4.8rem;
  }

  @media (max-width: ${screenResolutions.tablet}) {
    svg {
      margin-right: 3.2rem;
    }
  }

  @media (max-width: ${screenResolutions.mobile}) {
    font-size: 1.6rem;
    line-height: 1.9;
    margin-bottom: 2.4rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 2.4rem;

    svg {
      margin-right: 1.6rem;
    }
  }
`;
