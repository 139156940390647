import { createGlobalStyle } from 'styled-components';
interface Props {
  host: string;
}

export default createGlobalStyle`
  @import url("/fonts.css");
  * { box-sizing: border-box; margin: 0; padding: 0; }
  html { font-family: 'Inter-Regular', serif; font-size: 10px; }
  a,
  a:hover {
    text-decoration: none;
  }
`;
