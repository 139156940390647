import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../components/global-styles';
import SectionTitle from '../components/section-title';

const Wrapper = styled.section`
  padding-top: 20vh;
  text-align: center;

  h3 {
    font-size: 4.8rem;
  }

  p {
    color: ${props => props.theme.colors.darkBlueTransparent('0.8')};
    font-size: 2rem;
    line-height: 150%;
  }
`;

const NotFound = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Helmet title="Not Found | Ironhack Student Profile" />
        <GlobalStyles />
        <SectionTitle>404 - Page not found</SectionTitle>
        <p>The page you are trying to reach doesn't exist anymore.</p>
        <p>Please, contact Ironhack for more information.</p>
      </Wrapper>
    </ThemeProvider>
  );
};

export default NotFound;
