import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cluster from './pages/StudentCluster';
import NotFound from './pages/NotFound';
import { GlobalFonts } from '@ironhack/ih-components';

const App: React.FC = () => {
  return (
    <Router>
      <GlobalFonts />
      <Switch>
        <Route
          path="/clusters/:clusterId"
          render={(props: any): JSX.Element => <Cluster {...props} />}
        />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
