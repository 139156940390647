import _ from 'lodash';

const stringCapitalCase = (text: string): string =>
  _.replace(text, /(^|[\s-])\S/g, _.toUpper);

const getStudentCourse = (
  track: string
): {
  course: string;
  description: string;
} => {
  switch (track) {
    case 'da':
      return { course: 'data', description: 'Data Analytics Graduate' };
    case 'ux':
      return { course: 'uxui', description: 'UX/UI Design Graduate' };
    case 'wd':
      return { course: 'web', description: 'Web Development Graduate' };
    default:
      return { course: '', description: 'Graduate' };
  }
};

const getStudentName = (name: string, surname: string): string =>
  `${stringCapitalCase(name)} ${stringCapitalCase(surname)}`;

export { stringCapitalCase, getStudentCourse, getStudentName };
