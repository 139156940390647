import styled from 'styled-components';
import { screenResolutions } from '../_variables';

const SectionTitle = styled.h3`
  color: ${props => props.theme.colors.darkBlue};
  font-family: 'Inter-Bold';
  font-size: 3.2rem;
  line-height: 120%;
  margin: 0;
  margin-bottom: 1.6rem;

  @media (max-width: ${screenResolutions.tablet}) {
    font-size: 2.8rem;
  }

  @media (max-width: ${screenResolutions.mobile}) {
    font-size: 2.4rem;
  }
`;

export default SectionTitle;
