import styled from 'styled-components';
import { screenResolutions } from '../../components/_variables';
import { theme } from '../../theme';

export default styled.section`
  padding: 4.8rem 7.2rem 0;

  .cards-section {
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
  }

  &.expired {
    font-size: 1.6rem;
    line-height: 140%;
    text-align: center;

    h1 {
      color: ${theme.colors.errorRed};
      margin-bottom: 1.6rem;
    }

    button {
      margin-top: 4.8rem;
    }
  }

  @media (max-width: ${screenResolutions.desktopS}) {
    padding: 4.8rem 8rem 0;
  }

  @media (max-width: ${screenResolutions.tablet}) {
    padding: 3.2rem;

    .cards-section {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${screenResolutions.mobile}) {
    padding: 0;

    .cards-section {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
`;
